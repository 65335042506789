<template>
  <div class="Table MMTable">
    <!--  table toolbar  -->
    <div class="Table__toolbar">
      <div class="Table__toolbar-search">
        <search-input v-if="hasSearch" :value.sync="searchInput" />
        <div class="MMTable__toolbar-select">
          <common-select
            :value.sync="selectedCarrier"
            :options="carrierOptions"
            placeholder="業界"
            :on-change="handleChangeSelect"
          />
        </div>
        <div class="MMTable__toolbar-select">
          <common-select
            :value.sync="selectedPlan"
            :options="planOptions"
            placeholder="プラン"
            :on-change="handleChangeSelect"
          />
        </div>
      </div>
      <div class="Table__toolbar-buttons">
        <slot name="right" />
        <add-new-button v-if="hasAddButton" class-name="add-button" :func="onCreate" />
      </div>
    </div>
    <!--    table content  -->
    <div class="table-responsive">
      <b-table
        ref="ManagementMemberTable"
        :items="items"
        :fields="fields"
        sort-by="user_code"
        thead-class="Table__header MMTable__header"
        tbody-tr-class="Table__row MMTable__row"
        hover
      >
        <template #head()="data">
          <div v-if="!!data.label" class="header">
            <div class="header--value">
              <span>{{ data.label }}</span>
            </div>
            <div v-if="data.field.sortable" class="header--sort">
              <SortIcon />
            </div>
          </div>
          <div v-else class="action">
            {{ '' }}
          </div>
        </template>

        <template #cell(company_logo)="data">
          <img v-if="data.value" class="image" :src="data.value" alt="logo-image" />
          <img v-else class="image" :src="defaultCompanyLogo" alt="empty-logo" />
        </template>

        <template #cell(company_name)="data">
          <router-link :to="`/management-member/member-detail/${data.item.id}`">
            {{ data.value }}
          </router-link>
        </template>

        <template #cell(company_career_name)="data">
          <div>
            {{ data.value.name }}
          </div>
        </template>

        <template #cell(use_app)="data">
          <div class="ms-2">
            {{ data.value }}
          </div>
        </template>

        <template #cell(company_status)="data">
          <div class="text-center" :class="getClassStatus(data.item.company_status)">
            {{ getStatusLabel(data.item.company_status) }}
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="cell">
            <img
              src="@/assets/icons/ic_edit.svg"
              alt="edit-icon"
              @click.stop.prevent="onEdit(data.item)"
            />
          </div>
        </template>
      </b-table>
    </div>
    <!--   table paging   -->
    <div class="Table__paging">
      <table-paging
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
        :total="total"
        :options-paging="optionsPaging"
      />
    </div>
    <!--    dialog   -->
    <slot name="dialog" />
  </div>
</template>

<script>
import SearchInput from '@/components/commons/common-search-input'
import AddNewButton from '@/components/add-new-button'
import TablePaging from '@/components/commons/common-table-paging'
import SortIcon from '@/components/sort-icon'
import { managementMemberService } from '@/services/management-member-service'
import { DEFAULT_COMPANY_LOGO, COMPANY_STATUS } from '@/utils/constants'
import { stringToDateMonthJapan } from '@/utils/date-utils'
import { mapGetters } from 'vuex'
import CommonSelect from '@/components/commons/common-select'

export default {
  name: 'ManagementMemberTable',
  components: { SearchInput, AddNewButton, TablePaging, SortIcon, CommonSelect },
  props: {
    hasSearch: {
      type: Boolean,
      default: true,
    },
    hasAddButton: {
      type: Boolean,
      default: true,
    },
    showEditDialog: {
      type: Function,
      default: () => {
        return {}
      },
    },
    showAddDialog: {
      type: Function,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      searchInput: '',
      fields: managementMemberService.getFields(),
      items: [],
      currentPage: 1,
      perPage: 10,
      optionsPaging: [10, 50, 100],
      total: 0,
      dialogId: 'show',
      dialogTitle: '',
      formData: null,
      status: [],
      defaultCompanyLogo: DEFAULT_COMPANY_LOGO,
      selectedCarrier: '',
      selectedPlan: '',
    }
  },
  computed: {
    ...mapGetters('commonSettings', ['user_status', 'mt_packages', 'career']),
    carrierOptions() {
      const options = []
      this.career.forEach((item) => {
        options.push({
          value: item.id,
          text: item.name,
        })
      })
      return options
    },
    planOptions() {
      const options = []
      this.mt_packages.forEach((item) => {
        options.push({
          value: item.id,
          text: item.name,
        })
      })
      return options
    },
  },
  watch: {
    searchInput(newValue) {
      this.handleValueChange(newValue)
    },
    perPage() {
      this.handleValueChange()
    },
    currentPage() {
      this.handleValueChange()
    },
  },
  async created() {
    await this.reloadTable()
    this.status = this.user_status.map((item) => ({ value: item.id, text: item.name }))
  },
  methods: {
    refreshTable() {
      this.$refs['ManagementMemberTable'].refresh()
    },
    onEdit(rowData) {
      this.showEditDialog(rowData, () => this.reloadTable(true))
    },
    getStatusLabel(status) {
      const filterStatus = this.status.find((item) => item.value === status)
      if (filterStatus) return filterStatus.text
      else return null
    },
    getClassStatus(id) {
      if (id === COMPANY_STATUS.ACTIVE) {
        return 'cell__status cell__status-active'
      }
      return 'cell__status cell__status-inactive'
    },
    onCreate() {
      this.showAddDialog(() => this.reloadTable(true))
    },
    async reloadTable(isMaster = false) {
      // reload tables data
      this.searchInput = ''
      const { items, total } = await managementMemberService.getListData(
        this.perPage,
        this.currentPage,
        this.selectedCarrier,
        this.selectedPlan,
        this.searchInput,
        true
      )
      this.items = items
      this.total = total
      this.convertValue()
    },
    async handleValueChange(keyword) {
      const { items, total } = await managementMemberService.getListData(
        this.perPage,
        this.currentPage,
        this.selectedCarrier,
        this.selectedPlan,
        keyword
      )
      this.items = items
      this.total = total
      this.convertValue()
    },
    async handleChangeSelect() {
      const { items, total } = await managementMemberService.getListData(
        this.perPage,
        this.currentPage,
        this.selectedCarrier,
        this.selectedPlan,
        this.searchInput
      )
      this.items = items
      this.total = total
      this.convertValue()
    },
    getDateLabel(date) {
      if (date) {
        return stringToDateMonthJapan(date)
      }
    },
    convertDate(date) {
      const d = new Date(Date.parse(date))
      const lang = 'en'
      const year = d.toLocaleString(lang, { year: 'numeric' })
      let month = d.toLocaleString(lang, { month: 'numeric' })
      if (month.length === 1) {
        month = '0' + month
      }
      return `${year}-${month}`
    },
    convertValue() {
      this.items.map((item) => {
        if (item.company_start_date) {
          item.company_start_date = this.convertDate(item.company_start_date)
        }
        if (item.company_end_date) {
          item.company_end_date = this.convertDate(item.company_end_date)
        }
      })
    },
  },
}
</script>
