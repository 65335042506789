<template>
  <Layout>
    <page-layout page-titles="顧客管理">
      <template #table-content>
        <management-member-table
          :show-add-dialog="showAddDialog"
          :show-edit-dialog="showEditDialog"
        >
          <template #dialog>
            <c-modal :id="dialogId" :title="dialogTitle" :buttons="dialogButtons" size="xl">
              <management-member-modal-form
                :form-data.sync="formData"
                :trigger-validate="triggerValidate"
                :is-valid.sync="isValid"
              />
            </c-modal>
            <change-password-dialog
              :id="dialogChangePasswordId"
              :confirm-change-password="onChangePassword"
              :require-old-password="false"
            >
            </change-password-dialog>
          </template>
        </management-member-table>
      </template>
    </page-layout>
  </Layout>
</template>

<script>
import Layout from '@/layouts/main/app'
import PageLayout from '@/components/commons/common-page-layout'
import CModal from '@/components/commons/common-modal'
import ManagementMemberTable from '@/components/tables/management-member-table'
import ManagementMemberModalForm from '@/components/forms/management-member-modal-form'
import pick from 'lodash-es/pick'
import { managementMemberService } from '@/services/management-member-service'
import { mapActions } from 'vuex'
import { ALERT_TYPE } from '@/utils/constants'
import { MESSAGES } from '@/utils/messages'
import ChangePasswordDialog from '@/components/modals/change-password-modal'
import { isHttpLink } from '@/utils/object-helpers'

export default {
  name: 'ManagementMember',
  components: {
    ManagementMemberModalForm,
    ManagementMemberTable,
    Layout,
    PageLayout,
    CModal,
    ChangePasswordDialog,
  },
  data() {
    return {
      searchInput: '',
      formData: {},
      dialogId: 'show',
      dialogChangePasswordId: 'change-password-id',
      dialogTitle: '',
      dialogTitles: {
        ADD: {
          id: 1,
          text: '顧客新規登録',
        },
        EDIT: {
          id: 2,
          text: '顧客情報編集',
        },
        DELETE: {
          id: 3,
          text: '',
        },
      },
      onAddOrEditConfirm: () => {
        return {}
      },
      triggerValidate: false,
      isValid: false,
      requiredCreateKeys: [
        'company_name',
        'company_career',
        'company_phone',
        'company_domain',
        'email',
        'password',
        'company_capital',
        'company_package_id',
        'company_start_date',
        'company_end_date',
        'role_id',
        'company_status',
        'company_logo',
      ],
      requiredEditKeys: [
        'id',
        'company_name',
        'company_career',
        'company_phone',
        'company_domain',
        'email',
        'password',
        'company_package_id',
        'company_capital',
        'company_start_date',
        'company_end_date',
        'role_id',
        'company_status',
        'company_logo',
      ],
      dialogButtons: [],
    }
  },
  computed: {
    dialogAddButtons() {
      return [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: '保存',
          variant: 'primary',
          minWidth: 133,
          size: 'md',
          func: this.onAddOrEditConfirm,
        },
      ]
    },
    dialogEditButtons() {
      return [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: 'パスワード変更',
          variant: 'secondary',
          minWidth: 133,
          size: 'md',
          func: this.showChangePasswordDialog,
        },
        {
          title: '更新',
          variant: 'primary',
          minWidth: 133,
          size: 'md',
          func: this.onAddOrEditConfirm,
        },
      ]
    },
  },
  created() {
    this.fetchCommonSetting()
  },
  methods: {
    ...mapActions('alert', ['displayAlert']),
    ...mapActions('commonSettings', ['fetchCommonSetting']),
    showAddDialog(createSuccessCallback) {
      this.formData = null
      this.onAddOrEditConfirm = async () => {
        this.triggerValidate = !this.triggerValidate
        setTimeout(async () => {
          if (this.isValid) {
            const formData = pick(this.formData, this.requiredCreateKeys)
            if (isHttpLink(formData.company_logo)) {
              delete formData.company_logo
            }
            const { success, data } = await managementMemberService.createItem(formData)
            if (!success) {
              this.showAlertError(data)
              return
            }
            createSuccessCallback && createSuccessCallback()
            this.$bvModal.hide(this.dialogId)
            this.displayAlert({
              type: ALERT_TYPE.SUCCESS,
              messages: MESSAGES.COMMON.MSG01,
            })
          }
        }, 150)
      }
      this.dialogTitle = this.dialogTitles.ADD.text
      this.dialogButtons = this.dialogAddButtons
      this.includeFields = []
      this.$bvModal.show(this.dialogId)
    },
    showEditDialog(rowData, editSuccessCallback) {
      this.formData = { ...rowData }
      this.onAddOrEditConfirm = async () => {
        this.triggerValidate = !this.triggerValidate
        setTimeout(async () => {
          if (this.isValid) {
            this.callbackFn = editSuccessCallback
            await this.onUpdateConfirm()
          }
        }, 150)
      }
      this.dialogTitle = this.dialogTitles.EDIT.text
      this.dialogButtons = this.dialogEditButtons
      this.includeFields = []
      this.$bvModal.show(this.dialogId)
    },
    async onUpdateConfirm() {
      const formData = pick(this.formData, this.requiredEditKeys)
      if (isHttpLink(formData.company_logo)) {
        delete formData.company_logo
      }
      const { success, data } = await managementMemberService.editItem(formData)
      if (!success) {
        this.showAlertError(data)
        return
      }
      this.callbackFn && this.callbackFn()
      this.$bvModal.hide(this.dialogId)
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.COMMON.MSG02,
      })
    },
    getErrorMessage(messages) {
      const result = []
      if (messages.email) {
        result.push(MESSAGES.ADMIN_MT.ADM04)
      }
      if (messages.company_name) {
        result.push(MESSAGES.ADMIN_MT.ADM02)
      }
      if (messages.company_domain) {
        result.push(MESSAGES.ADMIN_MT.ADM03)
      }
      if (messages.package) {
        result.push(MESSAGES.ADMIN_MT.ADM11)
      }
      if (result.length === 0) {
        result.push(MESSAGES.COMMON.MSG15)
      }
      return result
    },
    showAlertError(messages) {
      const errorMessages = this.getErrorMessage(messages)
      errorMessages.forEach((mes) => {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: mes,
        })
      })
    },
    showChangePasswordDialog() {
      this.$bvModal.show(this.dialogChangePasswordId)
    },
    async onChangePassword(formData) {
      const { success } = await managementMemberService.changePassword(formData, {
        id: this.formData.id,
      })
      if (!success) {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: MESSAGES.COMMON.MSG15,
        })
        return
      }
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.COMMON.MSG01,
      })
      this.$bvModal.hide(this.dialogChangePasswordId)
    },
  },
}
</script>
